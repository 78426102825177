<template>
  <div class="p-3">
    <h5>通路清單</h5>
    <b-button
      variant="primary"
      :disabled="unbindList.length < 1 || isUnbinding"
      @click="handleUnbind"
      >解除綁定</b-button
    >
    <div class="row">
      <div class="col-12">
        <b-table
          striped
          hover
          responsive
          :items="customers"
          :fields="fields"
          :busy="isFetchSameCustomerList"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(edit)="data">
            <b-form-checkbox
              v-model="unbindList"
              :value="data.item.id"
              :disabled="data.item.merchant_type_name.type === 'LINE'"
            >
            </b-form-checkbox>
          </template>
          <template #cell(picture)="data">
            <div class="d-flex align-items-center justify-content-center">
              <b-button
                size="sm"
                class="mr-2"
                @click="$router.push({ name: 'CustomerDetailEdit' })"
              >
                <BIconPencilFill aria-hidden="true"></BIconPencilFill>
              </b-button>
              <b-avatar
                variant="secondary"
                :src="data.item.picture"
                size="2rem"
              ></b-avatar>
            </div>
          </template>
          <template #cell(name)="data">
            <router-link
              :to="{
                name: 'CustomerDetailView',
                params: {
                  customerID: data.item.id,
                },
              }"
              >{{ data.item.name }}
            </router-link>
          </template>
          <template #cell(computed_merchant_types)="data">
            <b-badge
              v-if="data.item.merchant_type_name"
              variant="primary"
              class="mr-1"
              >{{ data.item.merchant_type_name.type_name || '未知' }}</b-badge
            >
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { BAvatar, BIconPencilFill } from "bootstrap-vue";
import customerApi from "@/apis/customer";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  components: { BAvatar, BIconPencilFill },
  data() {
    return {
      isFetchSameCustomerList: false,
      isUnbinding: false,
      unbindList: [],
      customer: {},
      customers: [],
      fields: [
        {
          key: "edit",
          label: "",
        },
        {
          key: "picture",
          label: "",
        },
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "id",
          label: "平台ID",
        },
        {
          key: "mobile_phone",
          label: "手機",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "birth",
          label: "生日",
        },
        {
          key: "created_at",
          label: "加入時間",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        {
          key: "phone_verified",
          label: "手機驗證（是/否)",
          formatter: (value) => {
            return value ? '是' : '否';
          },
        },
        {
          key: "phone_verified_at",
          label: "手機驗證時間",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        {
          key: "registered_at",
          label: "註冊時間",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        {
          key: "accept_term_at",
          label: "條款接受時間",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        {
          key: "computed_merchant_types",
          label: "來源通路",
        },
      ],
    };
  },

  created() {
    this.getSameCustomerList();
  },

  methods: {
    async getSameCustomerList() {
      try {
        this.isFetchSameCustomerList = true;
        const { data } = await customerApi.getSameUserCustomers(
          this.$route.params.customerID
        );
        this.customers = data.data;
        this.customers.forEach((customer) => {
          if (! customer.merchant_type_name) {
            customer.merchant_type_name = {}
          }
        });
        this.customer = data.data.find(
          (customer) => customer.id == this.$route.params.customerID
        );
      } catch (e) {
        console.log(e);
      }
      this.isFetchSameCustomerList = false;
    },

    handleBack() {
      this.$router.push({ name: "CustomerList" });
    },

    async handleUnbind() {
      try {
        this.isUnbinding = true;
        await Promise.all(this.unbindList).then(async (customerID) => {
          return await customerApi.unbind(customerID);
        });
        this.getSameCustomerList(this.$route.params.customerID);
      } catch (error) {
        console.log(error);
      }
      this.isUnbinding = false;
    },
  },
};
</script>

<style></style>
