<template>
  <div class="row">
    <div class="col-12">
      <b-overlay :show="showLoading">
        <b-card>
          <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
            <h4 class="col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold">
              分店列表
            </h4>
            <div class="col-12 col-xl-8 d-flex flex-xl-row align-items-center align-items-xl-center justify-content-end" v-if="hasBranchCreatePermission">
              <b-button
                class="flex-shrink-0 mb-0 mr-2"
                variant="primary"
                v-b-modal="'modal-create-branch'"
              ><i class="fa fa-plus"></i>加入分店</b-button>
            </div>
          </div>

          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-overlay rounded="sm">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="branches"
                    :fields="filteredFields"
                    @sort-changed="handleSort"
                    empty-text="尚未綁定分店"
                    show-empty>
                    <template #cell(actions)="data" v-if="hasBranchEditPermission">
                      <b-button class="mr-1" variant="inverse-warning" v-if="!data.item.unbound_at" @click="unboundBranch(data.item)">
                        <span class="mdi mdi-link-variant-off"></span> 解除綁定
                      </b-button>
                      <b-button class="mr-1" variant="inverse-success" v-if="data.item.unbound_at" @click="reboundBranch(data.item)">
                        <span class="mdi mdi-link-variant"></span> 重新綁定
                      </b-button>
                      <b-button variant="inverse-danger" @click="deleteBranch(data.item)">
                        <span class="mdi mdi-delete"></span> 刪除
                      </b-button>
                    </template>
                  </b-table>
                </b-overlay>
              </div>
            </div>
            <CustomPagination
              :currentPage="query.page"
              :totalRows="totalRows"
              :perPage="query.per_page"
              @page-change="handlePageChange"
              @per-page-change="handlePerPageChange"
            />
          </b-card-text>
        </b-card>
      </b-overlay>
    </div>

    <b-modal
      id="modal-create-branch"
      title="新增分店"
      centered
      @hidden="resetModal"
      @ok="handleEditGroupBranch"
    >
      <form ref="form" @submit.stop.prevent="handleEditGroupBranch">
        <b-form-group>
          <b-form-select
            v-model="branchId"
            :options="myBranches"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <b-form-input
            id="name"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="boundType"
            placeholder="請輸入綁定類型"
          ></b-form-input>
        </b-form-group>
      </form>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          取消
        </b-button>
        <b-button size="sm" variant="success" @click="ok()">
          確認送出
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customerApi from "@/apis/customer";
// import { format} from "date-fns";
// import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
// import {mapGetters} from 'vuex';
import { paginationMixin } from "@/mixins/pagination";
import branchApi from "@/apis/branch";
import {mapState} from "vuex";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  mixins: [paginationMixin],
  components: {
    CustomPagination,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    upperMerchantId: {
      type: String,
      default: null,
    },
    upperCustomerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      showLoading: false,
      statusOptions: [
        { value: "above_belonged", text: "全部" },
        { value: "belonged", text: "未領取" },
        { value: "applied", text: "已領取" },
        { value: "redeemed", text: "已使用" },
      ],
      totalRows: 0,
      branches: [],
      boundedBranchIds: [],
      myBranches: [],
      canAssignBranchesBundles: [],
      fields: [
        {
          key: "branch_code",
          label: "分店編號",
        },
        {
          key: "branch_name",
          label: "分店",
        },
        {
          key: "bound_at",
          label: "綁定時間",
          // formatter: (value) => {
          //   return value
          //     ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
          //     : null;
          // },
          // sortable: true,
        },
        {
          key: "unbound_at",
          label: "解綁時間",
          // formatter: (value) => {
          //   return value
          //     ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
          //     : null;
          // },
          // sortable: true,
        },
        {
          key: "bound_type",
          label: "綁定類型",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      customers: [],
      selectedBranchBundle: null,
      redeemKeyWord: "",
      branchId: null,
      boundType: null,
      groupTypeId: null,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        sort_by: "bound_at",
        order_by: "desc",
        keyword_v2: null,
        branch_id: null,
        after: null,
        before: null,
      },
      permissionChecker: new PermissionChecker(),
      hasBranchCreatePermission: false,
      hasBranchEditPermission: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    filteredFields() {
      return this.fields.filter(
        (field) => !this.excludeFields.includes(field.key)
      );
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  mounted() {
    this.checkPermissions();
    this.fetchBranches();
    this.initialized = true;
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    "$route.query": {
      handler(newQuery) {
        if (!this.initialized) return;
        if (Object.keys(newQuery).length !== 0) {
          this.query = { ...newQuery };
        }
        this.fetchBranches();
      },
      deep: true,
    },
  },
  methods: {
    async fetchBranches() {
      this.showLoading = true;

      let params = {
        ...this.query,
        // 若 upperCustomer.id 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
        customer_id: this.upperCustomerId || "",
      };

      const response = await customerApi.getBranches(this.upperCustomerId, params);

      this.branches = response.data.data;
      this.totalRows = response.data.meta.total;
      this.boundedBranchIds = response.data.boundedBranchIds;
      this.showLoading = false;

      await this.fetchMyBranch();
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    resetModal() {
      this.branchId = "";
      this.groupTypeId = "";
      this.boundType = "";
    },
    async handleEditGroupBranch(bvModalEvt) {

      bvModalEvt.preventDefault();

      if(!this.branchId) {
        this.$swal.fire({
          title: "請選擇分店",
          type: "error",
        });
        return;
      }

      this.showLoading = true;

      this.$nextTick(() => {
        this.$bvModal.hide("modal-create-branch");
      });

      await this.createBranch(this.upperCustomerId, {
        branch_id: this.branchId,
        bound_type: this.boundType
      });

      await this.fetchBranches();
    },
    async fetchMyBranch() {
      try {
        if (this.myBranches.length > 0) {
          this.myBranches = [];
        }
        const {data} = await branchApi.getCurrentBelowBranches();
        const boundedBranchIdsArray = Object.values(this.boundedBranchIds);

        data.data.forEach((branch) => {
          if (branch.org_id === this.organization.id && !boundedBranchIdsArray.includes(branch.id)) {
            let text = `${branch.branch_code} ${branch.name}`;
            if (branch.level > 1) {
              text = "-".repeat(branch.level - 1) + " " + text;
            }
            this.myBranches.push({
              text: text,
              value: branch.id,
            });
          }
        });
      } catch (error) {
        this.$swal.fire({
          title: "取得分店資料錯誤",
          type: "error",
        });
      }
    },
    async createBranch(customerId, payload) {
      try {
        let response = await customerApi.createBranch(customerId, payload);

        if (response.status !== 200) {
          this.$swal.fire({
            title: response.data,
            type: "error",
          });
        }
      } catch (error) {
        if (error.response) {
          this.$swal.fire({
            title: error.response.data.message || "請求發生錯誤",
            type: "error",
          });
        } else {
          this.$swal.fire({
            title: "加入分店失敗",
            type: "error",
          });
        }
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    checkPermissions() {
      this.hasBranchCreatePermission = this.checkPermission([
        consts.CUSTOMER_BRANCH_CREATE,
      ]);
      this.hasBranchEditPermission = this.checkPermission([
        consts.CUSTOMER_BRANCH_EDIT,
      ]);
    },
    async unboundBranch(customerBranch) {
      await this.$swal
        .fire({
          title: "你確定要解除綁定嗎？",
          type: "warning",
          html: customerBranch.branch_name,
          confirmButtonText: "確定解除",
          cancelButtonText: "不解除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.showLoading = true;
            try {
              let response = await customerApi.unboundBranch(this.upperCustomerId, customerBranch.id);

              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功解除 ",
                });
              } else {
                if (response.data.message) {
                  this.$swal.fire({
                    title: "錯誤",
                    type: "error",
                    text: response.data.message,
                  });
                }

                this.showLoading = false;
              }
            }catch (error) {
              if (error.response) {
                this.$swal.fire({
                  title: error.response.data.message || "請求發生錯誤",
                  type: "error",
                });
              } else {
                this.$swal.fire({
                  title: "解除分店失敗",
                  type: "error",
                });
              }
            }

            await this.fetchBranches();
          }
        });
    },
    async reboundBranch(customerBranch) {
      await this.$swal
        .fire({
          title: "你確定要重新綁定嗎？",
          type: "warning",
          html: customerBranch.branch_name,
          confirmButtonText: "重新綁定",
          cancelButtonText: "不綁定",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.showLoading = true;
            try {
              let response = await customerApi.reboundBranch(this.upperCustomerId, customerBranch.id);

              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功綁定 ",
                });
              } else {
                if (response.data.message) {
                  this.$swal.fire({
                    title: "錯誤",
                    type: "error",
                    text: response.data.message,
                  });
                }

                this.showLoading = false;
              }
            }catch (error) {
              if (error.response) {
                this.$swal.fire({
                  title: error.response.data.message || "請求發生錯誤",
                  type: "error",
                });
              } else {
                this.$swal.fire({
                  title: "綁定分店失敗",
                  type: "error",
                });
              }
            }

            await this.fetchBranches();
          }
        });
    },
    async deleteBranch(customerBranch) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            this.showLoading = true;

            try {
              let response = await customerApi.deleteBranch(this.upperCustomerId, customerBranch.id);
              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功 ",
                });

              } else {
                if (response.data.message) {
                  this.$swal.fire({
                    title: "錯誤",
                    type: "error",
                    text: response.data.message,
                  });
                }
              }
            } catch(error) {
              if (error.response) {
                this.$swal.fire({
                  title: error.response.data.message || "請求發生錯誤",
                  type: "error",
                });
              } else {
                this.$swal.fire({
                  title: "刪除失敗",
                  type: "error",
                });
              }
            }

            await this.fetchBranches();
          }
        });
    },
  },
};
</script>
