<template>
  <b-card class="shadow-none rounded rounded-3 flex-fill mb-3">
    <div class="d-flex align-items-stretch">
      <div
        class="user-avatar d-flex flex-column justify-content-between"
      >
        <b-avatar
          size="6rem"
          :src="customer.data ? customer.data.pictureUrl : customer.avatar_url"
        ></b-avatar>

        <div
          v-if="$permissions.has($permissions.consts.CUSTOMER_LIST_EDIT)"
          class="text-center m-auto"
        >
          <b-button
            class="flex-shrink-0"
            variant="inverse-warning"
            :to="{
              name: 'CustomerDetailEdit',
              params: { customerID: customer.id },
            }"
          >
            編輯
          </b-button>
        </div>
      </div>
      <div class="wrapper pl-4 flex-fill">
        <div class="wrapper d-flex align-items-center justify-content-between">
          <a role="button" :class="showUnderline ? 'customer-name-underline' : 'customer-name'" @click="handleRedirectCustomer(customer.id)">
            <h4 class="mb-1 text-xl font-weight-medium">
              {{ customer.name }}
            </h4>
          </a>
          <template v-if="merchantTypeName">
            <span :id="`merchant-${customer.id}`" class="badge badge-primary badge-pill text-wrap">{{ merchantTypeName.substring(0,10) }}</span>
            <b-tooltip :target="`merchant-${customer.id}`" placement="top">{{ merchantName }}</b-tooltip>
          </template>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-account-card-details-${customer.id}`" class="mdi mdi-account-card-details mr-2"></i>
          <b-tooltip :target="`icon-account-card-details-${customer.id}`" placement="left">識別編號</b-tooltip>
          <p class="mb-0 text-muted text-sm">
            {{ customer.outer_code }}
          </p>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-cellphone-${customer.id}`" class="mdi mdi-cellphone mr-2"></i>
          <b-tooltip :target="`icon-cellphone-${customer.id}`" placement="left">手機</b-tooltip>
          <p class="mb-0 text-muted text-sm">
            {{ customer.mobile_phone }}
            <span
              v-if="customer.phone_verified_at"
              id="icon-phone-verified"
              class="mdi mdi-verified"
              style="color: #009100;"
            ></span>
            <b-tooltip
              v-if="customer.phone_verified_at"
              target="icon-phone-verified"
              placement="right"
            >
              手機驗證時間：{{ moment(customer.phone_verified_at).format('YYYY-MM-DD HH:mm') }}
            </b-tooltip>
          </p>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-email-outline-${customer.id}`" class="mdi mdi-email-outline mr-2"></i>
          <b-tooltip :target="`icon-email-outline-${customer.id}`" placement="left">E-mail</b-tooltip>
          <p class="mb-0 text-muted text-sm">{{ customer.email }}</p>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-gender-male-female-${customer.id}`" class="mdi mdi-gender-male-female mr-2"></i>
          <b-tooltip :target="`icon-gender-male-female-${customer.id}`" placement="left">性別</b-tooltip>
          <p class="mb-0 text-muted text-sm">{{ customer.sexual }}</p>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-cake-variant-${customer.id}`" class="mdi mdi-cake-variant mr-2" ></i>
          <b-tooltip :target="`icon-cake-variant-${customer.id}`" placement="left">生日</b-tooltip>
          <p class="mb-0 text-muted text-sm">{{ customer.birthday ? moment(customer.birthday).format('YYYY-MM-DD') : '' }}</p>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-account-plus-${customer.id}`" class="mdi mdi-account-plus mr-2" ></i>
          <b-tooltip :target="`icon-account-plus-${customer.id}`" placement="left">加入時間</b-tooltip>
          <p class="mb-0 text-muted text-sm">{{ customer.created_at ? moment(customer.created_at).format('YYYY-MM-DD HH:mm') : '' }}</p>
        </div>
        <div
          class="
            wrapper
            d-flex
            align-items-center
            font-weight-medium
            text-muted
          "
        >
          <i :id="`icon-account-check-${customer.id}`" class="mdi mdi-account-check mr-2" ></i>
          <b-tooltip :target="`icon-account-check-${customer.id}`" placement="left">註冊時間</b-tooltip>
          <p class="mb-0 text-muted text-sm">
            {{ customer.registered_at ? moment(customer.registered_at).format('YYYY-MM-DD HH:mm') : '' }}
            <span v-if="customer.registered_at" class="mdi mdi-verified" style="color: #009100;"></span>
          </p>
        </div>

        <div class="text-right mt-2">
          <b-button v-if="merchantType=='LINE'" class="mr-2" variant="inverse-success" @click="handleSyncLineData(customer)"  ><i class="mdi mdi-cloud-sync"></i>同步 LINE</b-button>
          <b-button
            v-if="showReset"
            class="mr-2"
            variant="outline-danger"
            v-b-tooltip.hover
            title="重設此帳號並解除所有綁定"
            @click="handleReset(customer)"
          >
            <span class="mdi mdi-autorenew"></span> 帳號重設
          </b-button>
          <b-button
            v-if="showUnregister"
            class="mr-2"
            variant="outline-danger"
            v-b-tooltip.hover
            title="將此帳號設定為未註冊狀態"
            @click="handleUnregister(customer)"
          >
            <span class="mdi mdi-logout"></span> 重新註冊
          </b-button>
          <template v-if="showUnbindButton && $permissions.has($permissions.consts.CUSTOMER_EDIT_UNBIND) && merchantType !== 'LINE'">
            <span :id="`unbind-${customer.id}`">
              <b-button variant="outline-danger" @click="handleUnbind(customer)" :disabled="merchantType=='LINE'" >
                <i class="mdi mdi-link-off"></i>解除綁定
              </b-button>
            </span>
            <b-tooltip v-if="merchantType=='LINE'" :target="`unbind-${customer.id}`" placement="top">LINE 通路無法解除綁定</b-tooltip>
            <b-tooltip v-else :target="`unbind-${customer.id}`" placement="top">解除此帳號的綁定</b-tooltip>
          </template>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import customerApi from "@/apis/customer";
import moment from 'moment'

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    showUnbindButton: {
      type: Boolean,
      default: false,
    },
    showUnderline: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    merchantName() {
      return this.customer.merchant ? this.customer.merchant.name : this.customer.merchant_type_name.name;
    },
    merchantType() {
      return this.customer.merchant ? this.customer.merchant.type : this.customer.merchant_type_name.type;
    },
    merchantTypeName() {
      return this.customer.merchant ? this.customer.merchant.type_name : this.customer.merchant_type_name.type_name;
    },
    showReset() {
      return this.merchantType == 'LINE' && this.$permissions.has(this.$permissions.consts.CUSTOMER_EDIT_RESET)
    },
    showUnregister() {
      return this.merchantType == 'LINE' && this.$permissions.has(this.$permissions.consts.CUSTOMER_EDIT_UNREGISTER)
    },
    moment () {
      return moment
    },
  },
  methods: {
    handleRedirectCustomer(id) {
      console.log('handleRedirectCustomer', id)
      this.$router.push({
        name: 'CustomerDetailView',
        params: {
          customerID: id,
          org_code: this.$route.params.org_code
        },
      }, ()=> { this.$emit("trigger-init-data") })
    },
    async handleSyncLineData(customer) {
      await customerApi.syncLineData(customer.id).then((response) => {
        if (response.data.success) {
          this.$swal("成功", "同步成功", "success").then(() => {
            this.$emit("trigger-fetch-customer");
          });
        } else {
          this.$swal("失敗", "同步失敗，請聯絡系統管理員", "warning");
        }
      });
    },
    async handleUnbind(customer) {
      try {
        this.$swal
          .fire({
            type: "warning",
            title: `您確定要將 ${customer.name} 解除綁定嗎？`,
            confirmButtonText: "確定解除綁定",
            cancelButtonText: "取消",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.value) {
              try {
                const response = await customerApi.unbind(customer.id);
                if (response.status === 200) {
                  await this.$swal.fire({
                    title: "成功",
                    type: "success",
                    text: "解除綁定成功",
                  });
                  this.$emit("trigger-init-data");
                }
              } catch (error) {
                this.$swal("失敗", error.response.data.message, "error");
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async handleReset(customer) {
      try {
        const html = `
          確定要重設此帳號嗎？<br>
          會恢復為未註冊狀態，並解除所有的綁定帳號。
        `
        this.$swal
          .fire({
            type: "warning",
            html,
            confirmButtonText: "確認重設",
            cancelButtonText: "取消",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.value) {
              try {
                const response = await customerApi.reset(customer.id);
                if (response.status === 200) {
                  await this.$swal.fire({
                    title: "成功",
                    type: "success",
                    text: "重設成功",
                  });
                   this.$emit("trigger-init-data");
                }
              } catch (error) {
                this.$swal("失敗", error.response.data.message, "error");
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async handleUnregister(customer) {
      try {
        this.$swal
          .fire({
            type: "warning",
            title: `確定要將此帳號 ${customer.name} 設定為未註冊嗎？`,
            text: '會清除帳號的手機號碼、與註冊時間。',
            confirmButtonText: "確認重設",
            cancelButtonText: "取消",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.value) {
              try {
                const response = await customerApi.unregister(customer.id);
                if (response.status === 200) {
                  await this.$swal.fire({
                    title: "成功",
                    type: "success",
                    text: "重設成功",
                  });
                  this.$emit("trigger-fetch-customer");
                }
              } catch (error) {
                this.$swal("失敗", error.response.data.message, "error");
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  },
}
</script>

<style scoped>
.customer-name-underline {
    color: var(--primary);
}

.customer-name {
    color: black;
}
</style>
